.BarcodeButton {
	@apply tw-border tw-border-solid tw-border-gray-400 tw-flex tw-cursor-pointer tw-flex-shrink-0;
	height: 27px;
	padding: 2px 0;
	&:focus {
		outline: 1px solid theme('colors.blue.DEFAULT');
		outline-offset: 2px;
	}
}
.BarcodeButton-icon {
	@apply tw-max-w-full tw-max-h-full;
}