.delivery-dates-container {
	.delivery-instructions,
	.zipCode-field-group {
		display: inline-block;
	}

	.zipCode-field-group {
		margin-right: 10px;

		label {
			font-weight: normal;
			margin-right: 90px;
		}

		input[type="text"] {
			display: inline-block;
			width: 74px;
			padding: 6px;
			margin-top: 0px;
		}

		.zipCode-validationMessage-deliveryDatesForm {
			position: absolute;
		}
	}
}

.delivery-dates-content {
	.delivery-result {
		line-height: 1.2;
	}
	.delivery-dates-disclaimer {
		margin-top: 10px;
		font-style: italic;
	}
}

.edit-zip-container {
    margin-bottom: 20px;
    strong {
        display: inline-block;
        margin-right: 20px;
    }
}


.edit-zip-form-container {
    margin-bottom: 10px;

    :global .zipCode-field-group-deliveryDatesForm {
        display: inline-block;
    }

    .zipCode-field-group {
        display: inline-block;
        margin-right: 10px;
    }

    .zipCode-control-deliveryDatesForm {
        margin-right: 10px;
        display: inline-block;
        width: 100px;
        padding: 6px;
        margin-top: 0;
    }

    .zipCode-validationMessage-deliveryDatesForm {
        position: absolute;
    }

    .zipCode-label-deliveryDatesForm {
        span {
            display: inline-block;
        }
    }

    strong {
        display: inline-block;
        margin-right: 20px;
        margin-left: 5px;
    }
}
