.GiftCardScannerFade-component {
	@apply tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full;
}
.GiftCardScannerFade-appear {
	@apply tw-opacity-0;
	&.GiftCardScannerFade-appear-active {
		@apply tw-transition-opacity tw-duration-200 tw-ease-in-out tw-opacity-100;
	}
}
.GiftCardScannerFade-leave {
	@apply tw-opacity-100;
	&.GiftCardScannerFade-leave-active {
		@apply tw-opacity-0 tw-transition-opacity tw-duration-200 tw-ease-in-out;
	}
}
