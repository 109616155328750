.GiftCardScanner {
	@apply tw-fixed;
	.modal-caption {
		opacity: 0.01 !important;
		height: 0 !important;
		transition: opacity 200ms ease-in-out, height 0ms 200ms;
	}
	.GiftCardScanner-container {
		@apply tw-fixed tw-w-full tw-h-full tw-bg-gray tw-top-0 tw-left-0;
	}
	.GiftCardScanner-closeBtn {
		@apply tw-fixed tw-top-0 tw-left-0 tw-p-6 tw-cursor-pointer tw-z-10;
	}
	.GiftCardScanner-videoContainer {
		@apply tw-fixed tw-w-full;
		// iPadOS does not apply the Tailwind class properly (tw-transform tw--translate-y-1/2) when building NextJS.
		transform: translateY(-50%);
		top: 50%;
	}
	.GiftCardScanner-video {
		@apply tw-fixed tw-top-0 tw-left-0;
		video,
		:global(.drawingBuffer) {
			// iPadOS does not apply the Tailwind class properly (tw-transform tw--translate-y-1/2) when building
			// NextJS.
			@apply tw-fixed tw-left-0 tw-w-full;
			transform: translateY(-50%);
			top: 50%;
		}
	}
	.GiftCardScanner-fileUploadContainer {
		@apply tw-fixed;
		// iPadOS does not apply the Tailwind class properly (tw-transform tw--translate-y-1/2) when building NextJS.
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
	}
	.GiftCardScanner-fileUploadLabel {
		@apply tw-text-white tw-font-bold tw-text-lg tw-text-center tw-mt-0 tw-mx-0 tw-mb-4;
	}
	.GiftCardScanner-fileUploadOptions {
		@apply tw-border-2 tw-border-solid tw-border-white tw-p-4 tw-bg-gray-200 #{'!important'};
		border-radius: 2px;
		> * + * {
			@apply tw-mt-4 tw-mx-0 tw-mb-0;
		}
	}
	.GiftCardScanner-hotSpotLabel {
		@apply tw-absolute tw-w-full tw-text-center tw-text-white tw-font-bold tw-z-10;
		@apply tw-text-lg;
		// iPadOS does not apply the Tailwind class properly (tw-transform tw--translate-y-1/2) when building NextJS.
		transform: translateY(-50%);
		top: calc(50% - 105px);
	}
}
