@media screen {
	.oeCartSearch.formz,
	.oeCustomerIdSearch.formz {
		@apply tw-flex-row;
		input[type='submit'] {
			align-self: baseline;
			margin: 0 0 0 10px;
		}
	}
	.customer-search-form {
		.wildcard-text {
			margin-bottom: 15px;
			width: 100%;
		}
		.email-suggestion {
			@apply tw-self-start;
		}
		.after-label {
			align-self: flex-start;
			margin-bottom: 5px;
			margin-top: -5px;
		}
	}
	.customers-side-menu-list-item {
		@apply tw-mb-2.5;
		.recent-customers-button {
			text-align: left;
		}
		.recent-customers-entry {
			color: inherit;
			font-size: inherit;
			word-wrap: break-word;
			word-break: break-all;
		}
		.recent-customers-last-activity-date {
			font-size: 12px;
		}
	}

	.oeCartSearch .cartId-label-oeCartSearch,
	.oeCustomerIdSearch .customerId-label-oeCustomerIdSearch {
		display: none;
	}
	.customerSideMenu {
		@apply tw-bg-gray-600;
	}
}
