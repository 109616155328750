.SellGiftCard {
	:global(.amount-validationMessage-sell-gift-card-form) {
		@apply tw-m-0 tw-text-red;
	}
}
.SellGiftCard-gunScanningView {
	@apply tw-flex tw-flex-col tw-justify-center tw-items-center;
	min-height: 208px;
}
.SellGiftCard-cardNumberControl {
	width: 200px;
}
.SellGiftCard-barcodeButton {
	+ .SellGiftCard-cardNumberControl {
		@apply tw-hidden;
	}
}
.SellGiftCard-amountLabel {
	@apply tw-p-0;
}
.SellGiftCard-amountFieldGroup {
	@apply tw-mt-0 tw-mx-0 tw-mb-4;
	min-height: 95px;
}
.SellGiftCard-amountControl {
	width: 90px;
}