@media screen {
	.side-menu-contents-shop {
		input[type='submit'] {
			min-width: 65px;
		}
		.oeShopQuickSearch {
			@apply tw-flex tw-flex-row;
			margin-top: 15px;
			label {
				width: 100%;
				padding: 0;
				margin: 0 0 5px;
			}
			input[type='submit'] {
				@apply tw-self-end;
				margin: 0 0 0 10px;
			}
		}
		:global .articleNumber-field-group {
			margin: 0;
			width: 100%;
		}

		.oeShopAdvancedSearch {
			margin-top: 15px;
		}

		&.side-menu-contents-shop {
			li {
				font-size: 16px;
				a,
				button {
					font-size: inherit;
					display: inline-block;
					padding: 10px 0;
					line-height: 1;
					&[href$='.pdf'] {
						background: url(//rnb.scene7.com/is/image/roomandboard/icon_pdf) no-repeat 100% 50%;
						padding-right: 30px;
					}
				}
			}
		}
	}

	.call-to-action-added-to-cart-modal {
		margin: 20px 0 0;
	}
	.shop-search-results {
		.search-results-header {
			@apply tw-flex tw-flex-row tw-font-bold;
			padding-bottom: 8px;
			border-bottom: 1px solid theme('colors.gray.400');
		}
		.shop-search-result-item {
			@apply tw-flex tw-flex-row tw-justify-between;
			padding-top: 10px;
			padding-bottom: 15px;
			border-bottom: 1px solid theme('colors.gray.400');
			&:last-child {
				border-bottom: none;
			}

			.item-info {
				@apply tw-flex tw-flex-row tw-items-baseline;
				padding-top: 5px;
				align-items: baseline;
			}
			.added-message {
				align-self: flex-end;
				padding: 5px 0 0 0;
			}
		}
		// columns
		.item {
			@apply tw-flex tw-flex-col;
			flex-basis: 175px;
			.sales-text {
				font-weight: bold;
				margin-bottom: 5px;
			}
		}
		.status {
			margin: 0 0 0 25px;
			flex-basis: 100px;
		}
		.unitPrice {
			margin: 0 0 0 10px;
			flex-basis: 70px;
		}
		.quantity {
			flex-basis: 65px;
			select {
				margin-top: 0;
				width: 50px;
			}
			margin: 0 0 0 10px;
		}
		.call-to-action {
			flex-basis: 130px;
			button {
				width: 100%;
			}
		}
	}

	.shop-search-error-message {
		margin: 0 0 40px;
	}

	.exceededMaxTop {
		padding-bottom: 20px;
		border-bottom: 1px solid theme('colors.gray.400');
		margin-bottom: 15px;
	}

	.exceededMaxBottom {
		padding-top: 20px;
		border-top: 1px solid theme('colors.gray.400');
		margin-top: 10px;
	}
}
